import React, { useEffect, useState, useRef } from "react";
import "../ChatScreens/Chatlist.css"; // Import CSS file for styling
import "../ChatScreens/ChatView.css"; // Import CSS file for styling
import Layout from "../../Component/Layout";
import axios from "axios";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "../../img/avatar.jpg";
import { FaArrowLeft } from "react-icons/fa"; // You can use any icon for the back arrow
import io from "socket.io-client";

const ChatView = () => {
  const { c_id } = useParams();
  const location = useLocation();
  const { chat_id, c_to, image, u_first_name } = location.state || {};
  const [chatsview, setChatsview] = useState([]);
  const [chatsLoader, setChatsLoader] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);


  const chatContainerRef = useRef(null);
  const Userid = localStorage.getItem("user_id"); // Get the logged-in user ID

  const socket = useRef(null);

  useEffect(() => {
    if (!socket.current) {
      socket.current = io("https://lunarsenterprises.com:3004/", {
        transports: ["websocket"],
      });

      socket.current.on("connect", () => {
        console.log("Socket connected:", socket.current.id);
      });

      socket.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });

      socket.current.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      // Dynamic event listener for the specific c_id
      socket.current.on(c_id, (message) => {
        console.log("Received message:", message);
        if (message.result && message.c_id === c_id) {
          setChatsview((prevChats) => [
            ...prevChats,
            {
              m_id: Date.now(), // Temporary ID
              m_message: message.message,
              m_to_id: message.recipient,
              m_date: message.date,
              m_time: message.time,
              m_status: "sent",
            },
          ]);
        } else {
          console.error("Failed to display message:", message);
        }
      });

      socket.current.emit("c_id", c_id, (response) => {
        console.log("Room join acknowledged:", response);
        if (response === true) {
          console.log("Room joined successfully.");
        } else {
          console.error("Room join failed. Response:", response);
        }
      });
    }

    return () => {
      if (socket.current) {
        console.log("Disconnecting socket...");
        socket.current.disconnect();
        socket.current = null;
      }
    };
  }, [c_id]);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever chatsview changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatsview]);

  useEffect(() => {
    handleChatview();
  }, [count]);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever chatsview changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatsview]);

  const handleChatview = async () => {
    setChatsLoader(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/message/list",
        {
          c_id: c_id,
          page_no: "",
          limit: "",
        },
        {
          headers: {
            user_id: Userid,
          },
        }
      );

      if (response.data.result === true) {
        setChatsview(response.data.data);
      } else {
        console.error("Failed to fetch messages:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    } finally {
      setChatsLoader(false);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    console.log("Emitting message:", newMessage);

    const message = {
      m_message: newMessage,
    };

    try {
      // Emit the "sent" event to the backend
      socket.current.emit(
        "sent",
        {
          message: newMessage,
          recipient: c_to,
          c_id: c_id,
          user_id: Userid,
        },
        (response) => {
          console.log("Message sent successfully:", response);
          if (response.result) {
            setChatsview((prevChats) => [
              ...prevChats,
              {
                ...message,
                m_date: moment().format("YYYY-MM-DD"),
                m_time: moment().format("HH:mm:ss"),
              },
            ]);
          } else {
            console.error("Failed to send message");
          }
        }
      );
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setNewMessage(""); // Clear the input after sending
    setCount(count + 1);

  };

  const Navigationchat = useNavigate();

  const handleBackClick = () => {
    Navigationchat("/Chat");
  };

  return (
    <Layout>
      <div className="chat-list-container">
        <div className="chat-itemview">
          <div className="chat-itemviewsec">
            <button className="back-arrow" onClick={handleBackClick}>
              <FaArrowLeft size={24} color="#fff" />
            </button>
            <img
              src={image || Avatar}
              alt="User Avatar"
              className="chat-image"
            />
          </div>
          <div className="chat-detail">
            <div className="chat-headerView">
              <div>
                <span className="chat-nameview">{u_first_name} </span>
              </div>
            </div>
          </div>
        </div>

        {chatsLoader ? (
          <p>Loading...</p>
        ) : (
          <>
            {chatsview.length > 0   ? (
              <div className="chat-container" ref={chatContainerRef}>
                {chatsview?.map((msg) => (
                  <div
                    key={msg.m_id}
                    className={`chat-messageview ${
                      msg.m_from_id === parseInt(Userid)
                        ? "sender"
                        : " receiver"
                    }`}
                  >
                    <div className="chat-bubble">
                      <p>{msg.m_message}</p>
                      <span className="chat-time">
                        {moment(msg.m_time, "HH:mm:ss").format("hh:mm A")}
                      </span>
                      <div>
                        <span className="chat-date">
                          {moment(msg.m_date).format("ddd, D MMM")}
                        </span>
                      </div>
                      <div>
                        <span className="chat-date">
                          {msg.m_status === "read" ? (
                            <i
                              className="fas fa-check-double"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-check"
                              style={{ color: "black" }}
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No Message Available</p>
            )}
          </>
        )}

        <div className="message-input-container">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message"
          />
          <button onClick={handleSendMessage} disabled={!newMessage.trim()}>
            Send
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default ChatView;
