import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import WhatsAppGroupList from "./WhatsAppGroupList";

const AddWhatsAppLink = () => {
  const [districts, setDistricts] = useState([]); // List of districts
  const [selectedDistrict, setSelectedDistrict] = useState(""); // Selected district ID
  const [groupLink, setGroupLink] = useState(""); // Group link input
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [loading, setLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState(""); // Success feedback
  const [errorMessage, setErrorMessage] = useState(""); // Error feedback

  // Fetch districts on component mount
  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3004/mlm/whatsapp-group/list",{} // Replace with your API for fetching districts
        );
        setDistricts(response.data.data); // Assuming the API returns an array of districts
      } catch (error) {
        console.error("Error fetching districts:", error);
        setErrorMessage("Failed to fetch districts. Please try again later.");
      }
    };

    fetchDistricts();
  }, []);

  // Handle form submission
  const handleSubmit = async () => {
    if (!selectedDistrict || !groupLink) {
      setErrorMessage("Please select a district and enter a group link.");
      return;
    }

    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/whatsapp-group/add",
        { district_id: selectedDistrict, group_link: groupLink }
      );

      if (response ) {
        setSuccessMessage("WhatsApp group link added successfully!");
      } else {
        setErrorMessage("Failed to add the group link. Please try again.");
      }
    } catch (error) {
      console.error("Error adding group link:", error);
      setErrorMessage("An error occurred while adding the group link.");
    } finally {
      setLoading(false);
    }
  };



  
  return (
    <>
    <div>
      <Button onClick={() => setShowModal(true)}>Add WhatsApp Group Link</Button>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add WhatsApp Group Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} */}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}

          <Form>
            <Form.Group>
              <Form.Label>Select District</Form.Label>
              <Form.Control
                as="select"
                value={selectedDistrict}
                onChange={(e) => setSelectedDistrict(e.target.value)}
              >
                <option value="">-- Select District --</option>
                {districts.map((district) => (
                  <option key={district.id} value={district.wg_id}>
                    {district.wg_district}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>WhatsApp Group Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group link"
                value={groupLink}
                onChange={(e) => setGroupLink(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? "Adding..." : "Add Link"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    <WhatsAppGroupList/>
    </>
  );
};

export default AddWhatsAppLink;
