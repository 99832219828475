


import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Row,
  Col,
  Container,
  Spinner,
  Alert,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import '../Screens/Productlist.css'

const Product = ({
  product,
  handleQuantityChange,
  quantities,
  handleBuyNow,
}) => (
  <Col xs={6} sm={6} md={4} lg={3} className="mb-4">
    <motion.article
      className="ezy__blog7-post"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="position-relative" onClick={() => handleBuyNow(product)}>
        <img
          src={`https://lunarsenterprises.com:3004/mlm/${product.p_image}`}
          alt={product.p_name}
          className="ezy-blog7-banner"
        />
      </div>
      <div className="ms-2 p-md-2">
        <p className="mt-1 ezy__blog7-title fontwgt">
          Price : {product.p_price}
        </p>
        <p
          className={`mt-2 ${
            product.p_stock > 5
              ? "text-success"
              : product.p_stock > 0
              ? "text-danger"
              : "text-muted"
          }`}
        >
          {product.p_stock > 0
            ? product.p_stock < 5
              ? `Hurry! Only ${product.p_stock} left`
              : `Available: ${product.p_stock}`
            : "Out of Stock"}
        </p>
        <div className="d-flex align-items-center mt-2">
          {/* <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleQuantityChange(product.p_id, -1)}
            disabled={quantities[product.p_id] <= 1}
          >
            -
          </Button> */}
          {/* <span className="mx-3">Availiable Stock 3533</span> */}
          {/* <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleQuantityChange(product.p_id, 1)}
          >
            +
          </Button> */}
        </div>
      </div>
      <div className="buttoncenter mt-3 mb-2">
        <Button
          className="button-product"
          // disabled={product.p_stock <= 0}
          onClick={() => handleBuyNow(product)}
        >
          <span className="Enroll">Delete</span>
        </Button>
      </div>
    </motion.article>
  </Col>
);

const Productlist = () => {
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [quantities, setQuantities] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { register, handleSubmit, watch, reset } = useForm();

  const Username = localStorage.getItem("user_name");
  const referral_id = localStorage.getItem("referral_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3004/mlm/product/list"
        );
        const result = response.data;

        if (result?.result) {
          const products = result.data.flatMap((category) => category.product);
          const initialQuantities = Object.fromEntries(
            products.map((product) => [product.p_id, 1])
          );
          setQuantities(initialQuantities);
          setCategories(result.data);
          setActiveTab(result.data[0]?.c_id.toString());
          setLoading(false);
        } else {
          throw new Error(result?.message || "Failed to fetch data.");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleQuantityChange = (p_id, increment) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [p_id]: Math.max((prevQuantities[p_id] || 1) + increment, 1),
    }));
  };

  const handleBuyNow = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  const onSubmit = (data) => {
    console.log("Order Submitted", data);
    handleCloseModal();
  };

  const quantity = watch("quantity") || 1;
  const totalPrice = selectedProduct ? selectedProduct.p_price * quantity : 0;

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <section className="ezy__epgrid12 ">
      <Container>
        <Row className="">
          <Col lg={9}>
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-3"
            >
              {categories.map((category) => (
                <Tab
                  key={category.c_id}
                  eventKey={category.c_id.toString()}
                  title={category.c_name}
                >
                  <Row>
                    {category.product.map((product) => (
                      <Product
                        key={product.p_id}
                        product={product}
                        handleQuantityChange={handleQuantityChange}
                        quantities={quantities}
                        handleBuyNow={handleBuyNow}
                      />
                    ))}
                  </Row>
                </Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>

      {/* Modal for Buy Now */}
      {/* <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="referral">
                <Form.Label>Referral Id</Form.Label>
                <Form.Control
                  type="text"
                  value={` ${referral_id}`} // Combining the two values
                  placeholder="Enter your name"
                  {...register("referral_id", { required: true })}
                  readOnly
                />
              </Form.Group>

              <Form.Group controlId="name">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedProduct.p_name}
                  placeholder="Enter your Product Name"
                  {...register("prdt_name", { required: true })}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="address" className="mt-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  {...register("address", { required: true })}
                />
              </Form.Group>

              <Form.Group controlId="Pincode" className="mt-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Pincode"
                  {...register("pincode", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="state" className="mt-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your state"
                  {...register("state", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="district" className="mt-3">
                <Form.Label>District</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your district"
                  {...register("district", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="taluk" className="mt-3">
                <Form.Label>Taluk</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your taluk"
                  {...register("taluk", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="corporation" className="mt-3">
                <Form.Label>Corporation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your corporation"
                  {...register("corporation", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="panchayat" className="mt-3">
                <Form.Label>Panchayat</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your panchayat"
                  {...register("panchayat", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="quantity" className="mt-3">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  defaultValue={1}
                  {...register("quantity", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="price" className="mt-3">
                <Form.Label>Total Price</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={`Rs. ${totalPrice.toFixed(2)}`}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal> */}
    </section>
  );
};

export default Productlist;
