import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const WhatsAppStatuslist = ({Refresh}) => {
  const [groups, setGroups] = useState([]); // List of groups
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Delete confirmation modal
  const [groupToDelete, setGroupToDelete] = useState(null); // Group selected for deletion

  // Fetch group list
  const fetchGroups = async () => {
    setLoading(true);
    setError(null);

    try {
      const role = {
        role: "admin",
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/task/list",
        role
      );

      setGroups(response.data.list || []);
      //   if (response.message === true) {
      // Update group list with "data" field from response
      //   } else {
      //     setError(response.message || "Failed to fetch groups. Please try again.");
      //   }
    } catch (err) {
      console.error("Error fetching groups:", err);
      setError("An error occurred while fetching groups.");
    } finally {
      setLoading(false);
    }
  };

  // Handle delete group
  const deleteGroup = async () => {
    if (!groupToDelete) return;

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:3004/mlm/task/delete",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ t_id: groupToDelete }),
        }
      );

      if (response.ok) {
        setGroups(groups.filter((group) => group.wg_id !== groupToDelete)); // Remove from list
        setShowDeleteModal(false);
        fetchGroups()
      } else {
        const data = await response.json();
        setError(data.message || "Failed to delete group. Please try again.");
      }
    } catch (err) {
      console.error("Error deleting group:", err);
      setError("An error occurred while deleting the group.");
    }
  };

  // Fetch groups on component mount
  useEffect(() => {
    fetchGroups();
  }, [Refresh]);

  return (
    <div className=" my-5">
      {/* <h2 className="mb-4">WhatsApp Groups</h2> */}

      {error && <div className="alert alert-danger">{error}</div>}
      {loading ? (
        <div>Loading groups...</div>
      ) : groups.length === 0 ? (
        <div>No status found.</div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Image</th>
              <th>Delete</th>

              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <tr key={group.wg_id}>
                <td>{index + 1}</td>
                <td>{moment(group.t_date).format("MMMM Do, YYYY")}</td>
                <td>
                  <img
                    src={`https://lunarsenterprises.com:3004/${group.t_image}`}
                    alt="Group Image"
                    style={{ width: "150px", height: "150px" }}
                  />
  </td>
  <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setGroupToDelete(group.t_id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Status? 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={deleteGroup}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WhatsAppStatuslist;
