import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../Component/Layout";
import "../Department/Department.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table } from "react-bootstrap";

const Department = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formData, setFormData] = useState({ role: "", ref_id: "" });
  const [liststaff, setListStaff] = useState([]);

  // Function to handle form submission to the server
  const submitDataToServer = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/assign/role",
        { role: data.roleName, ref_id: data.referralId }
      );
      console.log("Response:", response.data);
      reset();
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = (data) => {
    console.log("Form Data:", data);
    if (onSubmit) {
      onSubmit(data);
    }
    setFormData(data);
    submitDataToServer(data);
  };

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/stafflist"
      );
      setListStaff(response.data.list);
      console.log("Staff List Response:", response);
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="form-container">
        <h2>Add Department</h2>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="form-group">
            <label htmlFor="roleName">Role Name</label>
            <select
              className="selectstyle"
              id="roleName"
              {...register("roleName", { required: "Role name is required" })}
            >
              <option value="">Select a role</option>
              <option value="/add_income">Add Income</option>
              <option value="/Withdraw">Withdraw Request</option>
              <option value="/MyTabswhatsapp">Status</option>

              <option value="/Activation">Activation Wallet</option>



              <option value="/Chat">Chat</option>
              <option value="/PasswordList">User Password</option>
              <option value="/product"> Products</option>


            </select>
            {errors.roleName && (
              <p className="error">{errors.roleName.message}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="referralId">Referral ID</label>
            <input
              id="referralId"
              type="text"
              {...register("referralId", {
                required: "Referral ID is required",
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: "Invalid referral ID format",
                },
              })}
              placeholder="Enter referral ID"
            />
            {errors.referralId && (
              <p className="error">{errors.referralId.message}</p>
            )}
          </div>

          <button type="submit" disabled={loading}>
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </>
            ) : (
              "Add Role"
            )}
          </button>
        </form>
      </div>

      <div className="container mt-4">
        <h3>Staff List</h3>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Role</th>
                <th>Referal Id</th>
                <th>u_first_name Id</th>
              </tr>
            </thead>
            <tbody>
              {liststaff?.length > 0 ? (
                liststaff.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.u_email}</td>
                    <td>{item.u_role}</td>
                    <td>{item.u_referal_id}</td>
                    <td>{item.u_first_name}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No staff data available.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  );
};

export default Department;
