import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Col, Row } from "react-bootstrap";
import WhatsAppStatuslist from "./WhatsAppStatuslist";

const AddStatusTask = () => {
  const [refresh, setRefresh] = useState(0);

  const [taskDate, setTaskDate] = useState("");
  const [images, setImages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const userId = localStorage.getItem("user_id"); // Retrieve the user ID from local storage

  // Handle image selection and limit to 6 images
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    if (images.length + files.length > 6) {
      setErrorMessage("You can only upload up to 6 images.");
      return;
    }

    const updatedImages = [...images, ...files];
    setImages(updatedImages);
    setErrorMessage("");
  };

  // Remove a specific image
  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!taskDate || images.length === 0) {
      setErrorMessage("Please select a date and upload at least one image.");
      return;
    }

    const formData = new FormData();
    formData.append("task_date", taskDate);

    images.forEach((image, index) => {
      formData.append(`image[${index}]`, image);
    });

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/task/add",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            user_id: userId,
          },
        }
      );

      if (response.data) {
        setSuccessMessage("Task added successfully!");
        setTaskDate("");
        setImages([]);
        setRefresh(refresh+1)

      }
    } catch (error) {
      setErrorMessage("Failed to add task. Please try again.");
      console.error("Error adding task:", error);
    } finally {
      setLoading(false);
    }
  };
 

  return (
    <>
    <div className=" my-5">
      <h2>Add New Task</h2>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}

      <Form onSubmit={handleSubmit}>
        {/* Task Date */}
        <Form.Group className="mb-3">
          <Form.Label>Task Date</Form.Label>
          <Form.Control
            type="date"
            value={taskDate}
            onChange={(e) => setTaskDate(e.target.value)}
          />
        </Form.Group>

        {/* Image Upload */}
        <Form.Group className="mb-3">
          <Form.Label>Upload Images (Max 6)</Form.Label>
          <Form.Control
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
          />
        </Form.Group>

        {/* Preview and Remove Images */}
        {images.length > 0 && (
          <Row className="mb-3">
            {images.map((image, index) => (
             
             <Col key={index} xs={6} sm={4} md={3} className="text-center mb-4">
             {/* Image Preview */}
             <img
               src={URL.createObjectURL(image)}
               alt={`Preview ${index}`}
               className="img-thumbnail"
               style={{ maxWidth: "100%", maxHeight: "150px" }}
             />
             {/* Remove Button */}
             <Button
               variant="danger"
               size="sm"
               className="mt-2"
               onClick={() => handleRemoveImage(index)}
             >
               Remove
             </Button>
           </Col>
            ))}
          </Row>
        )}

        {/* Submit Button */}
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Add Task"}
        </Button>
      </Form>
    </div>
    <WhatsAppStatuslist Refresh={refresh}/>
    </>
  );
};

export default AddStatusTask;
