import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Layout from '../../Component/Layout';
import ActivationWallet from '../ActivationWallet/ActivationWallet';
import IncomeWithdrawwallet from '../IncomeWithdrawwallet/IncomeWithdrawwallet';
import ProductManagement from '../AddIncome';
import Productlist from '../Productlist';
import AddProduct from '../AddProduct';
import Productsummary from './Productsummary';

const Producttab = () => {
  const [key, setKey] = useState('home');

  return (
    <Layout>
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Product List">
        <Productlist/>
      </Tab>
      <Tab eventKey="profile" title="Adding Section">
        <AddProduct/>
      </Tab>
      <Tab eventKey="Order" title="Product Order List">
        <Productsummary/>
      </Tab>
    </Tabs>
    </Layout>
  );
};

export default Producttab;
