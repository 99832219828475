import React, { useState, useContext } from "react";
import "../css/edit.scss";
import Form from "react-bootstrap/Form";
import Layout from "../Component/Layout";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Context } from "../context/Context";
import axios from 'axios';

const Editmember = () => {
  const { logindetails } = useContext(Context);
  const location = useLocation();
  const editMember = location?.state?.Datas || {};

  const userId = logindetails.user_id || localStorage.getItem('user_id');
  const userApiKey = logindetails.user_api_key || localStorage.getItem('user_api_key');

  const date = editMember.dob || "";
  const newDate = date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : "";

  const [formData, setFormData] = useState({
    image: null,
    imagePreview: editMember.u_image ? `https://lunarsenterprises.com:3004/${editMember.u_image}` : "",
    first_name: editMember.u_first_name || "",
    dob: newDate,
    address: editMember.u_address || "",
    state: editMember.u_state || "",
    district: editMember.u_district || "",
    pincode: editMember.u_pincode || "",
    contact_number: editMember.u_mobile || "",
    alternative_number: editMember.u_alt_no || "",
    bank_name: editMember.b_name || "",
    account_name: editMember.acc_name || "",
    account_number: editMember.acc_number || "",
    ifsc_code: editMember.ifsc_code || "",
    branch_name: editMember.br_name || "",
    user_id: editMember.u_id || "",
  });

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "image") {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateFormData = new FormData();
    updateFormData.append("user_id", formData.user_id);
    updateFormData.append("first_name", formData.first_name);
    updateFormData.append("dob", formData.dob);
    updateFormData.append("address", formData.address);
    updateFormData.append("state", formData.state);
    updateFormData.append("district", formData.district);
    updateFormData.append("pincode", formData.pincode);
    updateFormData.append("contact_number", formData.contact_number);
    updateFormData.append("alternative_number", formData.alternative_number);
    updateFormData.append("bank_name", formData.bank_name);
    updateFormData.append("account_name", formData.account_name);
    updateFormData.append("account_number", formData.account_number);
    updateFormData.append("ifsc_code", formData.ifsc_code);
    updateFormData.append("branch_name", formData.branch_name);

    if (formData.image) {
      updateFormData.append("image", formData.image);
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/user/update",
        updateFormData,
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data.result === true) {
        console.log(response.data);
        
        alert("Profile updated successfully");
        localStorage.setItem('form_user_id', formData.user_id);
        setFormData((prevData) => ({
          ...prevData,
          image: null,
          imagePreview: "",
        }));
      } else {
        alert("Server timed out. Please log out and log in again.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="container-fluid pro">
        <Form onSubmit={handleSubmit}>
          <h4>My Profile</h4>

          <div className="pro-image">
            <Form.Control
              className="control"
              type="file"
              id="image"
              name="image"
              onChange={handleChange}
            />
            <div className="image-holder">
              {formData.imagePreview && (
                <img
                  src={formData.imagePreview}
                  alt="Preview"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              )}
            </div>
          </div>

          <Form.Label htmlFor="first_name">User Name</Form.Label>
          <Form.Control
            type="text"
            id="first_name"
            value={formData.first_name}
            onChange={handleChange}
          />

          <Form.Label htmlFor="dob">Date of birth</Form.Label>
          <Form.Control
            type="date"
            id="dob"
            value={formData.dob}
            onChange={handleChange}
          />

          <Form.Label htmlFor="address">Address</Form.Label>
          <Form.Control
            type="text"
            id="address"
            value={formData.address}
            onChange={handleChange}
          />

          <Form.Label htmlFor="state">State</Form.Label>
          <Form.Control
            type="text"
            id="state"
            value={formData.state}
            onChange={handleChange}
          />

          <Form.Label htmlFor="district">District</Form.Label>
          <Form.Control
            type="text"
            id="district"
            value={formData.district}
            onChange={handleChange}
          />

          <Form.Label htmlFor="pincode">Pincode</Form.Label>
          <Form.Control
            type="text"
            id="pincode"
            value={formData.pincode}
            onChange={handleChange}
          />

          <Form.Label htmlFor="contact_number">Contact number</Form.Label>
          <Form.Control
            type="text"
            id="contact_number"
            value={formData.contact_number}
            onChange={handleChange}
          />

          <Form.Label htmlFor="alternative_number">Alternative number</Form.Label>
          <Form.Control
            type="text"
            id="alternative_number"
            value={formData.alternative_number}
            onChange={handleChange}
          />

          <h4 style={{ marginTop: "20px" }}>Add Account</h4>

          <Form.Label htmlFor="bank_name">Bank Name</Form.Label>
          <Form.Control
            type="text"
            id="bank_name"
            value={formData.bank_name}
            onChange={handleChange}
          />

          <Form.Label htmlFor="account_name">Accountant Name</Form.Label>
          <Form.Control
            type="text"
            id="account_name"
            value={formData.account_name}
            onChange={handleChange}
          />

          <Form.Label htmlFor="account_number">Account number</Form.Label>
          <Form.Control
            type="text"
            id="account_number"
            value={formData.account_number}
            onChange={handleChange}
          />

          <Form.Label htmlFor="ifsc_code">IFSC Code</Form.Label>
          <Form.Control
            type="text"
            id="ifsc_code"
            value={formData.ifsc_code}
            onChange={handleChange}
          />

          <Form.Label htmlFor="branch_name">Branch Name</Form.Label>
          <Form.Control
            type="text"
            id="branch_name"
            value={formData.branch_name}
            onChange={handleChange}
          />

          <button type="submit">Submit</button>
        </Form>
      </div>
    </Layout>
  );
};

export default Editmember;
