import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { FaSearch } from "react-icons/fa";

const ActivationWallet = () => {
  const [userhistory, setUserhistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userlogin, setUserlogin] = useState(null);
  const [query, setQuery] = useState("");
  const [querytrans, setQuerytrans] = useState("");


  const storedUserId = localStorage.getItem("user_id");

  ///-----pagination---------------------

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust items per page as needed

  useEffect(() => {
    fetchUserHistory(currentPage,query ,querytrans);
  }, [currentPage,query,querytrans]);

  const fetchUserHistory = async (page, searchQuery = "" , searchQuerytrans = "") => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/upi/transaction/list",
        {
          upi_page_no: page,
          upi_limit: itemsPerPage,
          search: searchQuery,
          searchtrans: searchQuerytrans
        }
      );
      setUserhistory(response.data.list);
      setTotalPages(Math.ceil(response.data.upi_total_count / itemsPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching data. Please try again.");
      console.error("Error fetching user history:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
  
  }
  const handleSearchTrans = (e) => {
    const value = e.target.value;
    setQuerytrans(value);
  
  }


  const Activation = async (ut_id, newStatus) => {
    setUserlogin(ut_id);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/upi/transaction/approve",
        { ut_id: ut_id, status: newStatus }
      );

      if (response.data.result === true) {
        alert("Approval Successful");
        fetchUserHistory();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error approving user:", error);
    } finally {
      setUserlogin(null);
    }
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(`https://lunarsenterprises.com:3004/${imagePath}`);
    setShowModal(true);
  };

 
  return (
    <div className="container-fluid myprofile">
      <div className="search-box mb-3">
                      <FaSearch id="search-icon" />
                      <input
                        id="search"
                        type="text"
                        placeholder="Search by referral ID"
                        value={query}
                        onChange={handleSearch}
                      />
                    </div>

                    <div className="search-box mb-3">
                      <FaSearch id="search-icon" />
                      <input
                        id="search"
                        type="text"
                        placeholder="Search by Transaction  ID"
                        value={querytrans}
                        onChange={handleSearchTrans}
                      />
                    </div>
      {loading ? (
        <div className="mt-5">
          <ThreeDots color="#00BFFF" height={20} width={50} />
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
           

          <div className="table-responsive">
            <table className="table table-light table-hover shadow">
              <thead>
                <tr>
                  <th scope="col">Sl no</th>
                  <th scope="col">Transaction Id</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Referral Id</th>
                  <th scope="col">Payment slip</th>
                  <th scope="col">Activation</th>
                </tr>
              </thead>
              <tbody>
                {userhistory?.length > 0 ? (
                  userhistory?.map((details, index) => (
                    <tr key={index}>
                      <th scope="row">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </th>

                      <td>{details.ut_utr_no}</td>
                      <td>{details.ut_amount}</td>
                      <td>{details.u_referal_id}</td>
                      <td>
                        <img
                          src={`https://lunarsenterprises.com:3004/${details.ut_payment_slip}`}
                          alt="Payment Slip"
                          style={{
                            cursor: "pointer",
                            width: "50px",
                            height: "50px",
                          }}
                          onClick={() =>
                            handleImageClick(details.ut_payment_slip)
                          }
                        />
                      </td>
                      <td className="text-capitalize">
                        {details.ut_status === "pending" ? (
                          <span>
                            <Button
                              onClick={() =>
                                Activation(details.ut_id, "approved")
                              }
                              className="bg-success text-capitalize text-white me-2 mb-2"
                              disabled={userlogin === details.ut_id}
                            >
                              {userlogin === details.ut_id ? (
                                <ThreeDots
                                  color="#00BFFF"
                                  height={20}
                                  width={50}
                                />
                              ) : (
                                "Approve"
                              )}
                            </Button>
                            <Button
                              onClick={() =>
                                Activation(details.ut_id, "rejected")
                              }
                              className="bg-danger text-capitalize text-white"
                              disabled={userlogin === details.ut_id}
                            >
                              Reject
                            </Button>
                          </span>
                        ) : (
                          <Button
                            className={`${
                              details.ut_status === "approved"
                                ? "bg-success text-capitalize"
                                : "bg-danger text-capitalize"
                            } text-white`}
                            disabled
                          >
                            {details.ut_status}
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="pagination mt-3">
            <Button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => prev - 1)}
            >
              Previous
            </Button>
            <span className="mx-3 straight">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              Next
            </Button>
          </div>
        </>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Slip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img src={selectedImage} alt="Payment Slip" className="img-fluid" />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ActivationWallet;
