import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const WhatsAppGroupList = () => {
  const [groups, setGroups] = useState([]); // List of groups
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Delete confirmation modal
  const [groupToDelete, setGroupToDelete] = useState(null); // Group selected for deletion

  // Fetch group list
  const fetchGroups = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post("https://lunarsenterprises.com:3004/mlm/whatsapp-group/list");
     
      setGroups(response.data.data || []);
    //   if (response.message === true) {
    // Update group list with "data" field from response
    //   } else {
    //     setError(response.message || "Failed to fetch groups. Please try again.");
    //   }
    } catch (err) {
      console.error("Error fetching groups:", err);
      setError("An error occurred while fetching groups.");
    } finally {
      setLoading(false);
    }
  };

  // Handle delete group
  const deleteGroup = async () => {
    if (!groupToDelete) return;

    try {
      const response = await fetch("https://lunarsenterprises.com:3004/mlm/task/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: groupToDelete }),
      });

      if (response.ok) {
        setGroups(groups.filter((group) => group.wg_id !== groupToDelete)); // Remove from list
        setShowDeleteModal(false);
      } else {
        const data = await response.json();
        setError(data.message || "Failed to delete group. Please try again.");
      }
    } catch (err) {
      console.error("Error deleting group:", err);
      setError("An error occurred while deleting the group.");
    }
  };

  // Fetch groups on component mount
  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div className=" my-5">
      {/* <h2 className="mb-4">WhatsApp Groups</h2> */}

      {error && <div className="alert alert-danger">{error}</div>}
      {loading ? (
        <div>Loading groups...</div>
      ) : groups.length === 0 ? (
        <div>No groups found.</div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>District</th>
              <th>Group Link</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <tr key={group.wg_id}>
                <td>{index + 1}</td>
                <td>{group.wg_district}</td>
                <td>
                  {group.wg_group ? (
                    <a href={group.wg_group} target="_blank" rel="noopener noreferrer">
                      {group.wg_group}
                    </a>
                  ) : (
                    "No Link"
                  )}
                </td>
                {/* <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setGroupToDelete(group.wg_id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <FaTrash />
                  </Button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this group? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={deleteGroup}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WhatsAppGroupList;
