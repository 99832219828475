import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useForm, Controller, set } from "react-hook-form";
import Pagination from "@mui/material/Pagination";
import { FaSearch } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";

import "../../css/dashboard.scss";
import { json, useLocation, useNavigate } from "react-router-dom";
import "../../css/searchPage.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  Alert,
  Form,
  FormControl,
  ListGroup,
  ModalBody,
} from "react-bootstrap";
import { motion } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";
import Layout from "../../Component/Layout";
function PasswordList() {
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage, setMembersPerPage] = useState(15);
  const [activeMembers, setActiveMembers] = useState([]);
  const [inactiveMembers, setInactiveMembers] = useState([]);
  const [filterStatus, setFilterStatus] = useState();
  const [status, setStatus] = useState({});
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const [totalcount, setTotalcount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedMemberId(id);
    setShow(true);
  };
  const [incomeList, setIncomeList] = useState(true);

  const [incomemodal, setIcomemodal] = useState(false);
  const [incomemodalselect, setIcomemodalselect] = useState(true);
  const [incomemodalloader, setIcomemodalloader] = useState(true);
  const [incomemodalerror, setIcomemodalerror] = useState(false);
  const [incomemodalname, setIcomemodalname] = useState(false);

  useEffect(() => {}, []);

  const Openincomemodal = (uid) => {
    setIcomemodalselect(uid);
    setIcomemodal(true);
    Incomemodalprofile();
  };
  const Closeincomemodal = () => {
    setIcomemodal(false);
  };

  const Incomemodalprofile = async (u_ide) => {
    setIcomemodalloader(true);

    axios({
      url: "https://lunarsenterprises.com:3004/mlm/myincome",
      method: "GET", // Change to POST
      headers: {
        user_id: u_ide,

        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result) {
          setIncomeList(response.data.data);
          // setIcomemodalerror({
          //   type: "success",
          //   text: response.data.message,
          // });
          setTimeout(() => {
            setIcomemodalerror(null); // Clear the error after 6 seconds
          }, 2000);
        } else {
          setIcomemodalerror({
            type: "fail",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        // Handle error
        setIcomemodalerror({
          type: "error",
          text: "An error occurred while fetching income data.",
        });
      });
  };

  const notify = () => toast("Member deleted successfully");

  const handleFilterChanges = (e) => {
    setFilterStatus(e.target.value);
    fetchAllMembers(currentPage, query, e.target.value);
  };

  // Move to edit page
  const moveToEdit = async (editMember) => {
    var params = {
      user_id: editMember.u_id,
    };
    const response = await axios.post(
      "https://lunarsenterprises.com:3004/mlm/user-view",
      params,
      {}
    );
    let Datas = response.data.list[0];
    console.log(Datas);
    navigate(`/edit_member/${editMember.u_id}`, { state: { Datas } });
  };

  const filteredMembers = orderList.filter((member) => {
    if (filterStatus === "All") {
      return true;
    }
    return member.u_status === filterStatus;
  });

  const searchedMembers = filteredMembers.filter(
    (member) => member.u_referal_id.toLowerCase().includes(query.toLowerCase())
    // JSON.stringify(member.u_pincode).toLowerCase().includes(query.toLowerCase())
  );

  const email = location.state || {
    email: localStorage.getItem("admin_mail"),
    useID: localStorage.getItem("user_id"),
  };

  const userId = email.useID;
  const admin_mail = email.email;

  useEffect(() => {
    if (email.email) localStorage.setItem("admin_mail", email.email);
    if (email.useID) localStorage.setItem("user_id", email.useID);
  }, []);

  const lastPostIndex = currentPage * membersPerPage;
  const firstPageIndex = lastPostIndex - membersPerPage;
  const pageInView = searchedMembers.slice(firstPageIndex, lastPostIndex);

  const totalMembers = searchedMembers.length;
  const totalPages = Math.ceil(totalMembers / membersPerPage);

  const changeStatus = async (status, member_id) => {
    try {
      const params = {
        status: status,
        user_id: member_id,
      };

      await axios.post(
        "https://lunarsenterprises.com:3004/mlm/status-update",
        params,
        {
          headers: { u_id: userId },
        }
      );
      setStatus((prevStatuses) => ({
        ...prevStatuses,
        [member_id]: status,
      }));
      fetchAllMembers();
    } catch (error) {
      console.log(error);
    }
  };

  const limit = membersPerPage;

  const fetchAllMembers = async (page = 1, search = "", e) => {
    try {
      const params = {
        user_id: userId,
        page_no: page,
        filter: e,
        limit: 15,
        search, // Include search parameter
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/transaction/password/list",
        params
      );

      const members = response.data.list;
      if (response.data.result) {
        setOrderList(response.data.list);

        // setMembersPerPage(response.data.currentcount);
        setTotalcount(response.data.totalcount);
      } else {
        setIcomemodalerror({
          type: "success",
          text: "No member found.",
        });
        setTimeout(() => {
          setIcomemodalerror(null);
        }, 5000);
      }

      const active = orderList.filter((member) => member.u_status === "active");
      const inactive = orderList.filter(
        (member) => member.u_status === "inactive"
      );

      setActiveMembers(response.data.activeuser);
      setInactiveMembers(response.data.inactiveuser);
    } catch (error) {
      console.log(error);
    }
  };

  // Delete a member
  const deleteUser = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/delete",
        {
          u_id: selectedMemberId,
        },
        {
          headers: {
            user_id: userId,
            api_key: email.key,
          },
        }
      );
      if (response.data.result === true) {
        notify();
      }
      fetchAllMembers();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllMembers(currentPage, query);
  }, [currentPage, query]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to first page on new search
  };

  //------------------------

  const [incomemodalAdd, setIcomemodalAdd] = useState(false);
  const [incomemodalerrorAdd, setIcomemodalerrorAdd] = useState(false);
  const [selectIncome, setSelectIncome] = useState("");
  const [amount, setAmount] = useState("");

  const handleChangeIncome = (e) => {
    setSelectIncome(e.target.value);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (u_id) => {
    try {
        const dataAdd = { user_id: u_id };
        const response = await axios.post(
            "https://lunarsenterprises.com:3004/mlm/admin/transaction/password/reset",
            dataAdd
        );

        console.log("Response:", response.data);
        
        // Handle success response
        const newError = {
            types: "success",
            texts: response.data.message,
        };
        fetchAllMembers()

        setIcomemodalerrorAdd(newError); // Set the error message

        setTimeout(() => {
            setIcomemodalerrorAdd(null); // Clear the error after 2 seconds
        }, 2000);

    } catch (error) {
        console.error("Error:", error); // Handle error
    } finally {
        setIcomemodalAdd(false);
    }
};


  return (
    <Layout>
      <h1>User Password List</h1>

      <div className="search-box">
        <FaSearch id="search-icon" />
        <input
          id="search"
          type="text"
          placeholder="Search by referral ID"
          onChange={handleSearch}
        />
      </div>
      {incomemodalerror && (
        <Alert
          className="mt-3"
          variant={incomemodalerror.type === true ? " success" : "danger"}
        >
          {incomemodalerror.text}
        </Alert>
      )}
      <div className="mt-5">
      {incomemodalerrorAdd && (
      <Alert
  className="mt-3"
  variant={incomemodalerrorAdd && incomemodalerrorAdd.types === "success" ? "success" : "danger"}
>
  {incomemodalerrorAdd ? incomemodalerrorAdd.texts : ""}
</Alert>
      )}


        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "1000px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Name</th>
                <th scope="col">Transaction Password</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Referral ID</th>
                {/* <th scope="col">Status</th> */}
                {/* <th scope="col">Change</th> */}
                <th scope="col">Reset Password</th>
                {/* <th scope="col">Delete</th> */}
                {/* <th scope="col">Income</th> */}
              </tr>
            </thead>
            <tbody>
              {orderList?.length > 0 ? (
                orderList?.map((order, index) => (
                  <tr key={order.u_id}>
                    <td>{(currentPage - 1) * limit + index + 1}</td>
                    <td>{order.u_first_name}</td>
                    <td>
                      {order.u_transaction_password || "Not Set Password"}
                    </td>
                    <td>{order.u_mobile}</td>
                    <td>{order.u_referal_id}</td>
                    {/* <td>{order.u_status}</td> */}
                    {/* <td>
                      <select
                        onChange={(e) =>
                          changeStatus(e.target.value, order.u_id)
                        }
                        value={order.u_status}
                        style={{ border: "none", outline: "none" }}
                      >
                        <option value="All">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td> */}
                    {/* <td onClick={() => moveToEdit(order)}>
                      <MdModeEdit size={20} />
                    </td> */}
                    {/* <td onClick={() => handleShow(order.u_id)}>
                      <MdDelete size={20} />
                    </td> */}

                    <td
                      onClick={() => {
                        handleSubmit(order.u_id);
                     
                      }}
                    >
                      <Button>
                        <p>Reset Password</p>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No members found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Pagination
            count={Math.ceil(totalcount / limit)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={incomemodal} onHide={Closeincomemodal}>
        <Modal.Header closeButton>
          <h3>{incomemodalname}</h3>
        </Modal.Header>
        <ModalBody>
          {incomemodalerror && (
            <Alert
              className="mt-3"
              variant={incomemodalerror.type === true ? " success" : "danger"}
            >
              {incomemodalerror.text}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            {incomemodalerrorAdd && (
              <Alert
                className="mt-3"
                variant={
                  incomemodalerrorAdd.type === true ? " success" : "danger"
                }
              >
                {incomemodalerrorAdd.text}
              </Alert>
            )}

            <div className="mt-2">
              <label>Set New Password</label>
              <input
                type="number"
                value={amount}
                onChange={handleChangeAmount}
                placeholder="Password"
                required
              />
            </div>

            <button type="submit" disabled={incomemodalAdd}>
              {incomemodalAdd ? (
                <>
                  <ThreeDots color="#00BFFF" height={20} width={50} />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </ModalBody>
        <Modal.Footer>
          <Button variant="secondary" onClick={Closeincomemodal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Layout>
  );
}

export default PasswordList;
