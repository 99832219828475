import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useForm, Controller, set } from "react-hook-form";
import Pagination from "@mui/material/Pagination";
import { FaSearch } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import "../../css/dashboard.scss";
import { json, useLocation, useNavigate } from "react-router-dom";
import "../../css/searchPage.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  Alert,
  Form,
  FormControl,
  ListGroup,
  ModalBody,
} from "react-bootstrap";
import { motion } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";
import Layout from "../../Component/Layout";
function Productsummary() {
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage, setMembersPerPage] = useState(15);
  const [filterStatus, setFilterStatus] = useState();
  const [status, setStatus] = useState({});
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const [totalcount, setTotalcount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedMemberId(id);
    setShow(true);
  };
  const [incomeList, setIncomeList] = useState(true);

  const [incomemodal, setIcomemodal] = useState(false);
  const [incomemodalselect, setIcomemodalselect] = useState(true);
  const [incomemodalloader, setIcomemodalloader] = useState(true);
  const [incomemodalerror, setIcomemodalerror] = useState(false);
  const [incomemodalname, setIcomemodalname] = useState(false);

  useEffect(() => {}, []);

  const Openincomemodal = (uid) => {
    setIcomemodalselect(uid);
    setIcomemodal(true);
    Incomemodalprofile();
  };
  const Closeincomemodal = () => {
    setIcomemodal(false);
  };

  const Incomemodalprofile = async (u_ide) => {
    setIcomemodalloader(true);

    axios({
      url: "https://lunarsenterprises.com:3004/mlm/myincome",
      method: "GET", // Change to POST
      headers: {
        user_id: u_ide,

        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.result) {
          setIncomeList(response.data.data);
          // setIcomemodalerror({
          //   type: "success",
          //   text: response.data.message,
          // });
          setTimeout(() => {
            setIcomemodalerror(null); // Clear the error after 6 seconds
          }, 2000);
        } else {
          setIcomemodalerror({
            type: "fail",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        // Handle error
        setIcomemodalerror({
          type: "error",
          text: "An error occurred while fetching income data.",
        });
      });
  };

  const notify = () => toast("Member deleted successfully");

  const handleFilterChanges = (e) => {
    setFilterStatus(e.target.value);
    fetchAllMembers(currentPage, query, e.target.value);
  };

  // Move to edit page
  const moveToEdit = async (editMember) => {
    var params = {
      user_id: editMember.u_id,
    };
    const response = await axios.post(
      "https://lunarsenterprises.com:3004/mlm/user-view",
      params,
      {}
    );
    let Datas = response.data.list[0];
    console.log(Datas);
    navigate(`/edit_member/${editMember.u_id}`, { state: { Datas } });
  };

  const filteredMembers = orderList?.filter((member) => {
    if (filterStatus === "All") {
      return true;
    }
    return member.u_status === filterStatus;
  });

  //   const searchedMembers = filteredMembers?.filter(
  //     (member) => member.u_referal_id.toLowerCase().includes(query.toLowerCase())
  //     // JSON.stringify(member.u_pincode).toLowerCase().includes(query.toLowerCase())
  //   );

  const email = location.state || {
    email: localStorage.getItem("admin_mail"),
    useID: localStorage.getItem("user_id"),
  };

  const userId = email.useID;
  const admin_mail = email.email;

  useEffect(() => {
    if (email.email) localStorage.setItem("admin_mail", email.email);
    if (email.useID) localStorage.setItem("user_id", email.useID);
  }, []);

  const lastPostIndex = currentPage * membersPerPage;
  const firstPageIndex = lastPostIndex - membersPerPage;

  //   const totalMembers = searchedMembers?.length;
  //   const totalPages = Math.ceil(totalMembers / membersPerPage);

  const changeStatus = async (status, member_id) => {
    try {
      const params = {
        status: status,
        user_id: member_id,
      };

      await axios.post(
        "https://lunarsenterprises.com:3004/mlm/status-update",
        params,
        {
          headers: { u_id: userId },
        }
      );
      setStatus((prevStatuses) => ({
        ...prevStatuses,
        [member_id]: status,
      }));
      fetchAllMembers();
    } catch (error) {
      console.log(error);
    }
  };

  const limit = membersPerPage;

  const fetchAllMembers = async (page = 1, search = "", e) => {
    try {
      const params = {
        page: page,
        filter: e,
        limit: 15,
        search, // Include search parameter
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/order/list",
        params,
        {
          headers: {
            user_id: userId,
          },
        }
      );

      const members = response.data.list;
      if (response.data.result) {
        setOrderList(response.data.data);

        // setMembersPerPage(response.data.currentcount);
        setTotalcount(response.data.totalcount);
      } else {
        setIcomemodalerror({
          type: "success",
          text: "No member found.",
        });
        setTimeout(() => {
          setIcomemodalerror(null);
        }, 5000);
      }

      const active = orderList?.filter(
        (member) => member.u_status === "active"
      );
      const inactive = orderList?.filter(
        (member) => member.u_status === "inactive"
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Delete a member
  const deleteUser = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/delete",
        {
          u_id: selectedMemberId,
        },
        {
          headers: {
            user_id: userId,
            api_key: email.key,
          },
        }
      );
      if (response.data.result === true) {
        notify();
      }
      fetchAllMembers();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllMembers(currentPage, query);
  }, [currentPage, query]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to first page on new search
  };

  //------------------------

  const [incomemodalAdd, setIcomemodalAdd] = useState(false);
  const [incomemodalerrorAdd, setIcomemodalerrorAdd] = useState(false);
  const [selectIncome, setSelectIncome] = useState("");
  const [amount, setAmount] = useState("");

  const handleChangeIncome = (e) => {
    setSelectIncome(e.target.value);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page refresh
    setIcomemodalAdd(true);
    try {
      const dataAdd = {
        user_id: incomemodalselect,
        income_type: selectIncome,
        amount: amount,
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/amount/adjustment",
        dataAdd
      );

      console.log("Response:", response.data); // Handle success response
      if (response.data.result) {
        Closeincomemodal();
        setSelectIncome("");
        setAmount("");
        setIcomemodalerrorAdd({
          type: "success",
          text: response.data.message,
        });
        setTimeout(() => {
          setIcomemodalerrorAdd(null); // Clear the error after 6 seconds
        }, 2000);
      } else {
        setIcomemodalerrorAdd({
          type: "success",
          text: response.data.message,
        });
        setTimeout(() => {
          setIcomemodalerrorAdd(null); // Clear the error after 6 seconds
        }, 2000);
      }
    } catch (error) {
      console.error("Error:", error); // Handle error
    } finally {
      setIcomemodalAdd(false);
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleImageClick = (imagePath) => {
    setSelectedImage(`https://lunarsenterprises.com:3004/mlm/${imagePath}`);
    setShowModal(true);
  };

  return (
    <>
    {/* <h1>working ongoing</h1> */}
      <div className="filter-box">
        <select onChange={handleFilterChanges} value={filterStatus}>
          <option value="">All</option>
          <option value="pending">Pending</option>
          {/* <option value="inactive">Inactive</option> */}
        </select>
      </div>

      <div className="search-box">
        <FaSearch id="search-icon" />
        <input
          id="search"
          type="text"
          placeholder="Search by referral ID"
          onChange={handleSearch}
        />
      </div>
      {incomemodalerror && (
        <Alert
          className="mt-3"
          variant={incomemodalerror.type === true ? " success" : "danger"}
        >
          {incomemodalerror.text}
        </Alert>
      )}
      <div className="mt-5">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "1000px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Referral ID</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Address</th>

                <th scope="col">State</th>
                <th scope="col">District</th>
                <th scope="col">Corporation</th>
                <th scope="col">Taluk</th>
                <th scope="col">Panchayat</th>
                <th scope="col">Pincode</th>
                <th scope="col">Product Image</th>

                <th scope="col">Quantity</th>
                <th scope="col">Amount</th>

                <th scope="col">Total Amount</th>
              
               
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.length > 0 ? (
                orderList?.map((order, index) => (
                  <tr key={order.u_id}>
                    <td>{(currentPage - 1) * limit + index + 1}</td>
                    <td>{order.o_referral_id}</td>
                    <td>{order.o_mobile}</td>
                    <td>{order.o_address}</td>
                    <td>{order.o_state}</td>

                    <td>{order.o_district}</td>
                    <td>{order.o_corporation}</td>
                    <td>{order.o_taluk}</td>
                    <td>{order.o_panchayat}</td>
                    <td>{order.o_pincode}</td>

                    <td>
                        <img
                          src={`https://lunarsenterprises.com:3004/mlm/${order.p_image}`}
                          alt="orderimage"
                          style={{
                            cursor: "pointer",
                            width: "50px",
                            height: "50px",
                          }}
                          onClick={() =>
                            handleImageClick(order.p_image)
                          }
                        />
                      </td>
                   
                    <td>{order.o_quantity}</td>
                    <td>{order.  p_price}</td>

                    <td>{order.o_amount}</td>

                    <td
                      onClick={() => {
                        Openincomemodal(order.u_id);
                        Incomemodalprofile(order.u_id);
                        setIcomemodalname(order.u_first_name);
                     

                        setIcomemodalselect(order.u_id);
                      }}
                    >
                      <Button>
                        <p>order confirm</p>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No members found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Pagination
            count={Math.ceil(totalcount / limit)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={incomemodal} onHide={Closeincomemodal}>
        <Modal.Header closeButton>
          <h3>{incomemodalname}</h3>
        </Modal.Header>
        <ModalBody>
          {incomemodalerror && (
            <Alert
              className="mt-3"
              variant={incomemodalerror.type === true ? " success" : "danger"}
            >
              {incomemodalerror.text}
            </Alert>
          )}

          {/* <div>
            {incomeList && (
              <motion.div
                className="income-card"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="income-details">
                  <p>
                    <strong>Income :</strong> {incomeList.u_income}
                  </p>
                  <p>
                    <strong>Daily Income :</strong> {incomeList.u_daily_income}
                  </p>
                  <p>
                    <strong>Constant Income:</strong>{" "}
                    {incomeList.u_constant_income}
                  </p>
                  <p>
                    <strong>Repurchase Income:</strong>{" "}
                    {incomeList.u_repurchase_income}
                  </p>
                  <p>
                    <strong>Service Income:</strong>{" "}
                    {incomeList.u_service_income}
                  </p>
                  <p>
                    <strong>Crypto Income:</strong> {incomeList.u_crypto_income}
                  </p>
                  <p>
                    <strong>Activity Income:</strong>{" "}
                    {incomeList.u_activity_income}
                  </p>
                  <p>
                    <strong>Wallet:</strong> {incomeList.u_wallet}
                  </p>
                </div>
              </motion.div>
            )}
          </div> */}
          <form onSubmit={handleSubmit}>
            {incomemodalerrorAdd && (
              <Alert
                className="mt-3"
                variant={
                  incomemodalerrorAdd.type === true ? " success" : "danger"
                }
              >
                {incomemodalerrorAdd.text}
              </Alert>
            )}

            <div className="mt-2">
              <label className="mt-2">Select Income Type</label>
              <select
                value={selectIncome}
                className="p-2"
                onChange={handleChangeIncome}
                required
              >
                <option value="">Select Income Type</option>

                <option value="u_income">Income</option>
                <option value="u_daily_income">Daily Income</option>
                <option value="u_constant_income">Constant Income</option>
                <option value="u_repurchase_income">Repurchase Income</option>
                <option value="u_service_income">Service Income</option>
                <option value="u_crypto_income">Crypto Income</option>
                <option value="u_activity_income">Activity Income</option>
                <option value="u_wallet">Wallet</option>
              </select>
            </div>

            <div className="mt-2">
              <label>Amount</label>
              <input
                type="number"
                value={amount}
                onChange={handleChangeAmount}
                placeholder="Enter amount"
                required
              />
            </div>

            <button type="submit" disabled={incomemodalAdd}>
              {incomemodalAdd ? (
                <>
                  <ThreeDots color="#00BFFF" height={20} width={50} />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </ModalBody>
        <Modal.Footer>
          <Button variant="secondary" onClick={Closeincomemodal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Product Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img src={selectedImage} alt="image producr" className="img-fluid" />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Productsummary;
