import React, { useState, useContext, useEffect } from "react";
import Layout from "../Component/Layout";
import "../css/searchPage.scss";
import { Context } from "../context/Context";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductManagement() {
  const { logindetails } = useContext(Context);
  const [income, setIncome] = useState("");
  const [repurchaseIncome, setRepurchaseIncome] = useState("");
  const [referral_id, setReferral_id] = useState("");
  const [serviceIncome, setServiceIncome] = useState("");

  const [activityIncome, setActivtyIncome] = useState("");

  const [cryptoIncome, setCryptoIncome] = useState("");
  const [userId, setUserId] = useState("");
  const [userAPI, setUserAPI] = useState("");
  console.log(logindetails);

  useEffect(() => {
    if (logindetails?.user_id) {
      localStorage.setItem("userID", logindetails.user_id);
      localStorage.setItem("userAPI", logindetails.user_api_key);
      setUserId(logindetails.user_id);
      setUserAPI(logindetails.user_api_key);
    }

    const storedUserId = localStorage.getItem("userID");
    const storedUserAPI = localStorage.getItem("userAPI");

    if (storedUserId && storedUserAPI) {
      setUserId(storedUserId);
      setUserAPI(storedUserAPI);
    }
  }, [logindetails]);

  const notify = () => toast("Income added successfully");
  const notify2 = (message) => toast(message);

  const handleDailyIncome = async (e, type) => {
    e.preventDefault();

    let params = {};
    if (type === "daily") {
      params.daily_income = income;
    } else if (type === "repurchase") {
      params.repurchase_income = repurchaseIncome;
    } else if (type === "service") {
      params.service_income = serviceIncome;
    } else if (type === "crypto") {
      params.crypto_income = cryptoIncome;
    } else if (type === "activity") {
      params.referral_id = referral_id;
      params.activity_income = activityIncome;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/income/update",
        params,
        {
          headers: {
            user_id: userId,
            api_key: userAPI
          }
        }
      );
      console.log(userId, userAPI);

      if (response.data.result === true) {
        notify();
        setIncome("");
        setRepurchaseIncome("");
        setServiceIncome("");
        setReferral_id("")
        setActivtyIncome("")
        setCryptoIncome("");
      } else {
        notify2(response.data.message);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="income">
        <form onSubmit={(e) => handleDailyIncome(e, "daily")}>
          <div className="daily-income">
            <label htmlFor="daily-income">Enter daily income</label>
            <input
              type="number"
              id="daily-income"
              placeholder="enter amount"
              onChange={(e) => setIncome(e.target.value)}
              value={income}
              style={{ width: "150px" }}
            />
          </div>
          <button type="submit">Submit</button>
        </form>

        <form onSubmit={(e) => handleDailyIncome(e, "repurchase")}>
          <div className="daily-income">
            <label htmlFor="repurchase-income">Enter Repurchase income</label>
            <input
              type="number"
              id="repurchase-income"
              placeholder="enter amount"
              onChange={(e) => setRepurchaseIncome(e.target.value)}
              value={repurchaseIncome}
              style={{ width: "150px" }}
            />
          </div>
          <button type="submit">Submit</button>
        </form>

        <form onSubmit={(e) => handleDailyIncome(e, "service")}>
          <div className="daily-income">
            <label htmlFor="service-income">Enter Service income</label>
            <input
              type="number"
              id="service-income"
              placeholder="enter amount"
              onChange={(e) => setServiceIncome(e.target.value)}
              value={serviceIncome}
              style={{ width: "150px" }}
            />
          </div>
          <button type="submit">Submit</button>
        </form>

        <form onSubmit={(e) => handleDailyIncome(e, "crypto")}>
          <div className="daily-income">
            <label htmlFor="crypto-income">Enter Crypto income</label>
            <input
              type="number"
              id="crypto-income"
              placeholder="enter amount"
              onChange={(e) => setCryptoIncome(e.target.value)}
              value={cryptoIncome}
              style={{ width: "150px" }}
            />
          </div>
          <button type="submit">Submit</button>
        </form>

        <form onSubmit={(e) => handleDailyIncome(e, "activity")}>
          <div className="activity-incom">
            <label htmlFor="activity-incom">Enter Activity income</label>
            <div className="mt-2">

              <input
                // type="number"
                id="activity-income"
                placeholder="enter referral id"
                onChange={(e) => setReferral_id(e.target.value)}
                value={referral_id}
                style={{ width: "150px" }}
              />
            </div>
            <div className="mt-2">
              <input
                type="number"
                id="activity-income"
                placeholder="enter amount"
                onChange={(e) => setActivtyIncome(e.target.value)}
                value={activityIncome}
                style={{ width: "150px" }}
              />
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>

      <ToastContainer />
    </Layout>
  );
}

export default ProductManagement;
