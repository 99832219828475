import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Layout from '../../Component/Layout';
import ActivationWallet from '../ActivationWallet/ActivationWallet';
import IncomeWithdrawwallet from '../IncomeWithdrawwallet/IncomeWithdrawwallet';
import AddWhatsAppLink from './AddWhatsAppLink';
import Addststustask from './Addststustask';

const MyTabswhatsapp = () => {
  const [key, setKey] = useState('home');

  return (
    <Layout>
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Add WhatsApp Link">
        <AddWhatsAppLink/>
      </Tab>
      <Tab eventKey="profile" title="Add status task">
        <Addststustask/>
      </Tab>
      {/* <Tab eventKey="contact" title="Contact">
        <p>This is the content for the Contact tab.</p>
      </Tab> */}
    </Tabs>
    </Layout>
  );
};

export default MyTabswhatsapp;
