import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import Layout from "../Component/Layout";

export default function AddProduct() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    setMessage(null); // Clear any previous messages

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/category/add",
        data
      );

      if (response.data.result === true) {
        setMessage({ type: "success", text: response.data.message });
        reset(); // Clear the form
      } else {
        setMessage({ type: "error", text: response.data.message });
      }
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const {
    register: registerproduct,
    handleSubmit: handleSubmitproduct,
    reset: resetproduct,
    formState: { errors: errorproduct },
  } = useForm();
  const [loadingproduct, setLoadingproduct] = useState(false);
  const [messageproduct, setMessageproduct] = useState(null);

  const onSubmitproduct = async (data) => {
    setLoadingproduct(true);
    setMessageproduct(null); // Clear previous messages

    // Create FormData for the file upload
    const formData = new FormData();
    formData.append("c_id", data.c_id);
    formData.append("price", data.price);
    formData.append("product_name", data.product_name);
    formData.append("stock", data.stock);
    formData.append("image", data.image[0]); // Handle the file input

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/product/add",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data.result === true) {
        setMessageproduct({
          type: "success",
          text:response.data.message,
        });
        resetproduct(); // Clear the form
      } else {
        setMessageproduct({
          type: "error",
          text: response.data.message,
        });
      }
    } catch (error) {
      setMessageproduct({
        type: "error",
        text: "An error occurred. Please try again later.",
      });
    } finally {
      setLoadingproduct(false);
    }
  };

  useEffect(() => {
    CategoryList();
  }, []);

  const [category_List, setCategory_List] = useState([]);
  const CategoryList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/category/list"
      );
      console.log(response.data.data);
      setCategory_List(response.data.data);
    } catch (error) {}
  };
  return (
    <>
      <div className=" ">
        <h3>Add Category</h3>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="category_name">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter category name"
              {...register("category_name", {
                required: "Category name is required",
              })}
              isInvalid={!!errors.category_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category_name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>

        {message && (
          <Alert
            className="mt-3"
            variant={message.type === "success" ? "success" : "danger"}
          >
            {message.text}
          </Alert>
        )}
      </div>

      <div className=" mt-5">
        <h3>Add Product</h3>
        <Form onSubmit={handleSubmitproduct(onSubmitproduct)}>
          <Form.Select
            {...registerproduct("c_id", {
              required: "Please select a category",
            })}
            isInvalid={!!errorproduct.c_id}
          >
            <option value="">Select a category</option>
            {category_List.length > 0 &&
              category_List.map((category, index) => (
                <option key={index} value={category.c_id}>
                  {category.c_name}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errorproduct.c_id?.message}
          </Form.Control.Feedback>
          {/* Price Field */}

          <Form.Group className="mb-3 mt-3" controlId="product_name">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text" // Changed to "text" since we want letters, not numbers
              placeholder="Enter product name (optional)"
              {...registerproduct("product_name", {
                // required: "Product name is required",
                pattern: {
                  value: /^[A-Za-z\s]+$/, // Regex for letters and spaces only
                  message: "Product name must contain only letters and spaces",
                },
              })}
              // isInvalid={!!errorproduct.product_name}
            />
            <Form.Control.Feedback type="invalid">
              {errorproduct.product_name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter price"
              {...registerproduct("price", {
                required: "Price is required",
                min: { value: 1, message: "Price must be greater than 0" },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Price must be a valid number",
                },
              })}
              isInvalid={!!errorproduct.price}
            />
            <Form.Control.Feedback type="invalid">
              {errorproduct.price?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="stock">
            <Form.Label>No of Stock</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter stock"
              {...registerproduct("stock", {
                required: "stock is required",
                min: { value: 1, message: "Price must be greater than 0" },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "stock must be a valid number",
                },
              })}
              isInvalid={!!errorproduct.stock}
            />
            <Form.Control.Feedback type="invalid">
              {errorproduct.stock?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Image Upload Field */}
          <Form.Group className="mb-3" controlId="image">
            <Form.Label>Product Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              {...registerproduct("image", {
                required: "Product image is required",
              })}
              isInvalid={!!errorproduct.image}
            />
            <Form.Control.Feedback type="invalid">
              {errorproduct.image?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Submit Button with Spinner */}
          <Button type="submit" variant="primary" disabled={loadingproduct}>
            {loadingproduct ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>

        {/* Success/Error Message */}
        {messageproduct && (
          <Alert
            className="mt-3"
            variant={messageproduct.type === true ? " danger" : "success"}
          >
            {messageproduct.text}
          </Alert>
        )}
      </div>
    </>
  );
}
